body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Paralucent','Mulish','Muli','sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Paralucent';
    font-style: normal;
    font-weight: 800;
    font-display: block;
    src: local(''), url('fonts/Paralucent-DemiBold.woff2') format('woff2'), /* Super Modern Browsers */
}

/* mulish-regular - latin */
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-display: block;
    src: url('fonts/mulish-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('fonts/mulish-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/mulish-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/mulish-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/mulish-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/mulish-v10-latin-regular.svg#Mulish') format('svg'); /* Legacy iOS */
}